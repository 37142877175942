const Main = () => { return (

  <div className="container mt-5">
    <div className="row mt-5">
      <div className="col-sm-6 col-md-6 col-lg-6 mt-5">
        <img src="./imageGirl.webp" alt="garota" width="100%"/>
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6 mt-5">
        <img src="./imageBoy.webp" alt="garota" width="100%"/>
      </div>
    </div>

    <div className="row mt-5">
      <center>
        <h2>Levando sua operação para proximo nivel</h2>
      </center>
      <div className="col-sm-6 col-md-6 col-lg-6 mt-5">
        <p><strong>O que é Lorem Ipsum?</strong></p>
        <p>Lorem Ipsum é simplesmente um texto fictício da indústria de impressão e composição tipográfica. Lorem Ipsum tem sido o texto fictício padrão da indústria desde 1500, quando um impressor desconhecido pegou uma prova de tipos e a misturou para fazer um livro de espécimes de tipos. Ela sobreviveu não apenas a cinco séculos, mas também ao salto para a composição tipográfica eletrônica, permanecendo essencialmente inalterada. Foi popularizado na década de 1960 com o lançamento de folhas Letraset contendo passagens de Lorem Ipsum e, mais recentemente, com software de editoração eletrônica como Aldus PageMaker, incluindo versões de Lorem Ipsum.</p>
      </div>
      <div className="col-sm-6 col-md-6 col-lg-6 mt-5">
        <p><strong>O que é Lorem Ipsum?</strong></p>
        <p>Lorem Ipsum é simplesmente um texto fictício da indústria de impressão e composição tipográfica. Lorem Ipsum tem sido o texto fictício padrão da indústria desde 1500, quando um impressor desconhecido pegou uma prova de tipos e a misturou para fazer um livro de espécimes de tipos. Ela sobreviveu não apenas a cinco séculos, mas também ao salto para a composição tipográfica eletrônica, permanecendo essencialmente inalterada. Foi popularizado na década de 1960 com o lançamento de folhas Letraset contendo passagens de Lorem Ipsum e, mais recentemente, com software de editoração eletrônica como Aldus PageMaker, incluindo versões de Lorem Ipsum.</p>
      </div>
    </div>
  </div>

)}

export default Main;
