const Header = () => { return (

<nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#5c3bff' }}>
  <div className="container-fluid">
    <a className="navbar-brand" href="/">
      <img src="./logowhite.png" alt="Logo" width="30" height="24" />
      <span className="nav-item text-light mx-2">Scriptsky System</span>
    </a>
    <button className="navbar-toggler text-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <img src="./menus.png" alt="Logo" width="24" height="24" />    
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link text-light" href="/solucoes">Soluções</a>
        </li>
        <li className="nav-item">
          <a href="/parceiros" className="btn text-light">Parceiros</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-light" href="/suporte">Suporte</a>
        </li>
        <li className="nav-item">
          <a className="nav-link text-light" href="/sobre">Sobre</a>
        </li>
      </ul>
      <div className="d-flex">
        <a className="nav-link dropdown-toggle text-light" href="/#" data-bs-toggle="dropdown" aria-expanded="false">
          Login
        </a>
        <ul className="dropdown-menu dropdown-menu-end">
          <li><a className="dropdown-item" href="/inicio">Entrar</a></li>
          <li><a className="dropdown-item" href="/inicio">Sair</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>

)}

export default Header;
