import Header from './components/pages/header/header';
import Footer from './components/pages/footer/footer';
import Main from './components/pages/main/main';
//import Routes from './routes';

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;

