const Footer = () => { return (
    
  <footer className="text-center mt-5" style={{ backgroundColor: '#5c3bff' }}>
    <div className="text-center p-3">
      <span className="text-light">&copy; 2024 Copyright:</span>
      <a className="text-light mx-2" href="https://scriptsky.com.br/">
        Scriptsky System
      </a>
    </div>
  </footer>

)}

export default Footer;
